import React from "react";
import { Trans } from "react-i18next";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Label, Text } from "@onlinesales-ai/label-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";

import "./index.less";

const Radio = ({
  className,
  onChange,
  label,
  icon,
  iconTooltip,
  labelDefinationKey,
  inputClassName,
  labelClass,
  id,
  tooltip,
  subTitle,
  subTitleProps,
  iconImage,
  ...props
}) => {
  const renderLabelWithIcon = (labelClass, label, id, icon, iconImage) => {
    return (
      <label className={"os-custom-radio-label " + labelClass} htmlFor={id}>
        {icon && (
          <span className={`icon ${icon} radio-icon ${label && "has-label"}`} />
        )}
        {iconImage ? (
          <AsyncImage imgSrc={iconImage} imgClassName="radio-image" />
        ) : null}
        <Trans>{label}</Trans>
        {subTitle ? <Text type="primary" size="small">{subTitle}</Text> : null}
      </label>
    );
  };

  return (
    <WithTooltip title={tooltip}>
      <div className={`radio-wrapper ${props.disabled ? "disabled" : ""} ${props.checked ? "checked" : ""} ${className || ""}`}>
        <input
          id={id}
          type="radio"
          className={"os-custom-radio " + inputClassName}
          {...props}
          onChange={(event) => {
            if (onChange) {
              onChange(event.target.checked, event);
            }
          }}
        />
        {(label || icon || iconTooltip || labelDefinationKey) && (
          <Label
            containerClass="os-custom-radio-label"
            labelDefinationKey={labelDefinationKey}
            wrapperTagName="label"
            icon={icon}
            wrapperProps={{
              htmlFor: id,
            }}
            fallBackLabel={
              <>
                {iconTooltip ? (
                  <WithTooltip title={iconTooltip}>
                    {renderLabelWithIcon(labelClass, label, id, icon, iconImage)}
                  </WithTooltip>
                ) : (
                  <>{renderLabelWithIcon(labelClass, label, id, icon, iconImage)}</>
                )}
              </>
            }
          />
        )}
      </div>
    </WithTooltip>
  );
};

Radio.defaultProps = {
  name: "",
  label: "",
  inputClassName: "",
  labelClass: "",
};

export default Radio;
